import { default as abuseReportsTovyXvT5XMMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93FSwCJVhQpgMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/admin/auditLog/[username]/[id].vue?macro=true";
import { default as index7yecGT1juaMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/admin/index.vue?macro=true";
import { default as moderation3VgNl7fgf2Meta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/admin/moderation.vue?macro=true";
import { default as pendingBans49Yr2ZfevtMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/admin/pendingBans.vue?macro=true";
import { default as profilesnYr58kJmolMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/admin/profiles.vue?macro=true";
import { default as index6mSwfOkyDLMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/admin/timesheets/index.vue?macro=true";
import { default as overview4PDyOjhngtMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingfo8XPa99MeMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingWQHVDE4F3OMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/admin/translations/missing.vue?macro=true";
import { default as usersSTUiqoD0h6Meta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/admin/users.vue?macro=true";
import { default as apiqJPQhfQekKMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/api.vue?macro=true";
import { default as _91slug_93dB6dlG4aFKMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/blog/[slug].vue?macro=true";
import { default as indexLRlZICpdkoMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93JdfIvyGbA1Meta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_933TfqWLCXTMMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminKMFJN4A9seMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/census/admin.vue?macro=true";
import { default as indexq01AKi7LnCMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/census/index.vue?macro=true";
import { default as contactmAZEqZvkiXMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/contact.vue?macro=true";
import { default as designqdOJOK6VAEMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/design.vue?macro=true";
import { default as englishp9wGCsfkd8Meta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/english.vue?macro=true";
import { default as faq5UZe6VzqdRMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/faq.vue?macro=true";
import { default as inclusiveK5IaB16QRDMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/inclusive.vue?macro=true";
import { default as indexzuXgkdkqmMMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/index.vue?macro=true";
import { default as licenses1BtwjRHvTMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/license.vue?macro=true";
import { default as academictyN4tCYzq9Meta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/links/academic.vue?macro=true";
import { default as indexdHBsUPXBMYMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/links/index.vue?macro=true";
import { default as media6nQzDfjJ7eMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/links/media.vue?macro=true";
import { default as translinguisticsvlty9zEqIFMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/links/translinguistics.vue?macro=true";
import { default as zinecxAYmvpWXKMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/links/zine.vue?macro=true";
import { default as namesSZiDTXlPmXMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/names.vue?macro=true";
import { default as index9eL1giZGsxMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/nouns/index.vue?macro=true";
import { default as templates8JwWtevbtNMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/nouns/templates.vue?macro=true";
import { default as peopleZ4ovOQtkMOMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/people.vue?macro=true";
import { default as privacybcJynlpISgMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/privacy.vue?macro=true";
import { default as _91username_93gf0JPKJsMkMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_933sejLesTEoMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/profile/card-[username].vue?macro=true";
import { default as editorCg2DtiYqr7Meta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/profile/editor.vue?macro=true";
import { default as anyp88YlVjUFIMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/pronouns/any.vue?macro=true";
import { default as avoidinghbt6uz3QbhMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/pronouns/avoiding.vue?macro=true";
import { default as indexsXjluQ18XzMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/pronouns/index.vue?macro=true";
import { default as mirrorKJ1S2V8kSuMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/pronouns/mirror.vue?macro=true";
import { default as pronounZh7hxDoQMsMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesmfcuGaYqRqMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/sources.vue?macro=true";
import { default as teamkcHWUOn3ypMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/team.vue?macro=true";
import { default as terminologywtv2fhbBGEMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/terminology.vue?macro=true";
import { default as terms1hs20yqGQzMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/terms.vue?macro=true";
import { default as userWsNca0U9whMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/user.vue?macro=true";
import { default as workshopslw3zSyyLJHMeta } from "/home/admin/www/pronoms.fr/release/20240916202910/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportsTovyXvT5XMMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/admin/abuseReports.vue").then(m => m.default || m)
  },
  {
    name: "admin-auditLog-username-id",
    path: "/admin/auditLog/:username()/:id()",
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/admin/auditLog/[username]/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/admin/moderation.vue").then(m => m.default || m)
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBans49Yr2ZfevtMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/admin/pendingBans.vue").then(m => m.default || m)
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/admin/profiles.vue").then(m => m.default || m)
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/admin/timesheets/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/admin/timesheets/overview.vue").then(m => m.default || m)
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/admin/translations/awaiting.vue").then(m => m.default || m)
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/admin/translations/missing.vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    name: "api",
    path: "/api",
    meta: apiqJPQhfQekKMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/api.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93dB6dlG4aFKMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexLRlZICpdkoMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93JdfIvyGbA1Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/calendar/[[year]].vue").then(m => m.default || m)
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_933TfqWLCXTMMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/calendar/[year]-[month]-[day].vue").then(m => m.default || m)
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminKMFJN4A9seMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/census/admin.vue").then(m => m.default || m)
  },
  {
    name: "census",
    path: "/census",
    meta: indexq01AKi7LnCMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/census/index.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactmAZEqZvkiXMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/design.vue").then(m => m.default || m)
  },
  {
    name: "english",
    path: "/english",
    meta: englishp9wGCsfkd8Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/english.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    meta: faq5UZe6VzqdRMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusiveK5IaB16QRDMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/inclusive.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/license.vue").then(m => m.default || m)
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academictyN4tCYzq9Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/links/academic.vue").then(m => m.default || m)
  },
  {
    name: "links",
    path: "/links",
    meta: indexdHBsUPXBMYMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/links/index.vue").then(m => m.default || m)
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: media6nQzDfjJ7eMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/links/media.vue").then(m => m.default || m)
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsvlty9zEqIFMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/links/translinguistics.vue").then(m => m.default || m)
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zinecxAYmvpWXKMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/links/zine.vue").then(m => m.default || m)
  },
  {
    name: "names",
    path: "/names",
    meta: namesSZiDTXlPmXMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/names.vue").then(m => m.default || m)
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: index9eL1giZGsxMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/nouns/index.vue").then(m => m.default || m)
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templates8JwWtevbtNMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/nouns/templates.vue").then(m => m.default || m)
  },
  {
    name: "people",
    path: "/people",
    meta: peopleZ4ovOQtkMOMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/people.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacybcJynlpISgMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93gf0JPKJsMkMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/profile/[username].vue").then(m => m.default || m)
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_933sejLesTEoMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/profile/card-[username].vue").then(m => m.default || m)
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editorCg2DtiYqr7Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/profile/editor.vue").then(m => m.default || m)
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyp88YlVjUFIMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/pronouns/any.vue").then(m => m.default || m)
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidinghbt6uz3QbhMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/pronouns/avoiding.vue").then(m => m.default || m)
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexsXjluQ18XzMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/pronouns/index.vue").then(m => m.default || m)
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorKJ1S2V8kSuMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/pronouns/mirror.vue").then(m => m.default || m)
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounZh7hxDoQMsMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/pronouns/pronoun.vue").then(m => m.default || m)
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesmfcuGaYqRqMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/sources.vue").then(m => m.default || m)
  },
  {
    name: "team",
    path: "/team",
    meta: teamkcHWUOn3ypMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/team.vue").then(m => m.default || m)
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologywtv2fhbBGEMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/terminology.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    meta: terms1hs20yqGQzMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "user",
    path: "/user",
    meta: userWsNca0U9whMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/user.vue").then(m => m.default || m)
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopslw3zSyyLJHMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20240916202910/pages/workshops.vue").then(m => m.default || m)
  }
]