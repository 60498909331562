import routerOptions0 from "/home/admin/www/pronoms.fr/release/20240916202910/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.11.5_eslint@8.57.0_rollup@4.21.0_sass@1.32.12_typescript@5.5.2_vite@5.4.3_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/home/admin/www/pronoms.fr/release/20240916202910/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}